<template>
  <div class="flex-row border-b dark:border-warm-gray-700">
    <div class="flex justify-between items-center mb-8">
      <h2 class="text-xl font-extrabold text-warm-gray-700 tracking-wider dark:text-warm-gray-100">Reviewers</h2>
    </div>
  </div>

  <div v-if="reviewer" class=" flex justify-center mt-14 mb-2 text-warm-gray-600 dark:text-warm-gray-300">
    <div class="w-10/12 lg:w-7/12">
      <!-- header 1 -->
      <div class="flex justify-between items-end">
        <div class="flex justify-start items-center pl-2">
          <span class="text-warm-gray-500 dark:text-warm-gray-400">Viewing {{ reviewer.name  }} information.</span>
        </div>
        <div class="flex justify-end items-center">
          <router-link :to="`/reviewer/edit/${reviewerId}`" class="bg-yellow-700 text-white font-semibold py-2 px-4 mr-2 rounded-md hover:bg-yellow-800 focus:bg-yellow-800 focus:outline-none ">Update</router-link>
          <button @click="$router.go(-1)" class="bg-warm-gray-300 font-semibold py-2 px-4 rounded-md hover:bg-warm-gray-400 focus:bg-warm-gray-400 focus:outline-none dark:bg-warm-gray-800 dark:hover:bg-warm-gray-700 dark:focus:bg-warm-gray-700">Return</button>
        </div>
      </div>  

      <!-- Information -->
      <div class="border shadow rounded-md p-4 mt-4 dark:border-warm-gray-700">
        <p class=" text-warm-gray-500 italic mb-4">{{reviewerId}}</p>
        <div class="mb-2">
          <div class="font-semibold dark:text-warm-gray-100 ">Reviewer name</div>
          <div>{{ reviewer.name  }}</div>
        </div>
        <div class="mb-2">
          <div class="font-semibold dark:text-warm-gray-100">Description</div>
          <div>{{ reviewer.description  }}</div>
        </div>
      </div>

      <!-- courses wrapper -->
      <div>
        <!-- header -->
        <div class="mt-6 mb-2">
          <span class="text-warm-gray-500 dark:text-warm-gray-400">Courses under this reviewer.</span>
        </div>
        <!-- course table -->
        <div class="border rounded-md overflow-hidden dark:border-warm-gray-700">
          <table class="w-full text-left">
            <thead class=" bg-warm-gray-300 dark:bg-warm-gray-700">
              <tr>
                <th class="p-4">Course Name</th>
                <th class="p-4">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="course in reviewer.courses" class="hover:bg-warm-gray-100 dark:hover:bg-warm-gray-800">
                <td class="p-2 pl-4">{{ course.name }}</td>
                <td class="p-2 pl-4">{{ course.description }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import {viewReviewer} from '../firebase'

const props = defineProps({
  reviewerId: {type: String, require: true }
})

const reviewer = ref(null);

onMounted(async ()=> {
  let res = await viewReviewer(props.reviewerId);

  if(!res.isError){
    reviewer.value = res.data;
  }
})
</script>